// eslint-disable-next-line no-redeclare,no-unused-vars
function buildChat(xhr) {
  window.chats = {};
  var cont = $(
    '<div id="chat" class="py-3 d-flex flex-column flex-shrink-0 justify-content-center"></div>'
  ).appendTo('main');

  $.each(xhr.ids, function (i, id) {
    var row = getObjectValue(data, ['chat', id]);
    var to;
    if (row.to) to = getObjectValue(data, ['user', row.to]);
    else if (row.to2) to = getObjectValue(data, ['customer', row.to2]);
    if (!to) return;

    // avatar
    var avatar = $(
      '<div class="avatar avatar-md position-relative my-1 me-3 link"></div>'
    ).appendTo(cont);
    var src;
    if (to.avatar && to.avatar.length > 50) src = to.avatar;
    else if (to.pic) src = getObjectValue(data, ['document', to.pic, 'file']);
    if (src)
      $('<img class="img-fluid rounded-circle" src="' + src + '">').appendTo(
        avatar
      );
    else {
      if (!to.acronym || to.acronym == 'upp') to.acronym = '';
      $(
        '<span class="avatar-title rounded-circle">' + to.acronym + '</span>'
      ).appendTo(avatar);
    }

    // click
    avatar.on(click, function () {
      avatar.addClass('border border-2 border-secondary');
      var afterClose = function () {
        avatar.removeClass('border border-2 border-secondary');
      };
      if (row.empty)
        detail({
          object: 'chat',
          new: row,
          modal: true,
          afterClose: afterClose,
        });
      else
        ajax({
          object: 'chat',
          id: row.id,
          func: 'detail',
          modal: true,
          afterClose: afterClose,
        });
    });

    // status
    if (to.online)
      $(
        '<span class="position-absolute top-0 start-100 translate-middle p-2 bg-success border border-light rounded-circle"></span>'
      ).appendTo(avatar);
    avatar.addTooltip('Chat mit ' + to.short + (to.online ? ' (online)' : ''));

    // profile
    contextMenu(avatar, {detail: 'Profil'}, function (type) {
      if (type == 'detail')
        ajax({
          object: 'user',
          objectSub: 'staff',
          id: row.to,
          func: 'detail',
          modal: true,
        });
    });
  });

  // height scroller
  if (cont.children(':last-child').offset().top > $(window).height()) {
    cont
      .removeClass('justify-content-center')
      .attr('data-mdb-perfect-scrollbar', true);
    new mdb.PerfectScrollbar(cont[0], {suppressScrollX: true});
  }
}
